import { useState } from 'react';
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import logo from "../img/logos/creaction_logo.png";

const Wrapper = styled.nav`
  width: 100%;
  height: 7vh;
  background-color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid #dcdfe6;
  position: fixed;
  z-index: 10;
  @media screen and (min-width: 800px) and (max-width: 1300px) {
    height: 10vh;
    //4k 사이즈 css 깨짐 방지
  }
`;  
const NavContainer = styled.div`
  max-width: 1000px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Logo = styled.div`
  width: 170px;
  height: 7vh;
  background: url(${logo}) center/contain no-repeat;  
  @media screen and (max-width:1000px) {
    width: 150px;    
    margin-left: 15px;
  }
  @media screen and (min-width: 800px) and (max-width: 1300px) {
    height: 10vh;
    //4k 사이즈 방지용
  }
`;
const MenuContainer = styled.ul`
  width: 400px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width:1000px) {
    display: none;
  }
`;
const Menu = styled.li`
  font-size: 15px;  
  a {    
    padding: 1.5vh 0;
    &:hover {
      color: #12b886;
      border-bottom: 2px solid #12b886;
    }    
  }
  &:last-child {
    padding: 1.5vh 0;
    div {      
      width: 60px;
      height: 32px;
      border: 1px solid #12b886;       
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: #12b886;
      }
      &:hover {
        background-color: #12b886;
        span {
          color: #ffffff;
        }
        transition: all 0.3s ease-in-out;
      } 
    }
  }
`;
const MobileBtn = styled.div`
  display: none;
  i {display: none;}
  @media screen and (max-width:1000px) {
    display: block;
    padding-right: 20px;    
    i {
      display: block;
      font-size: 30px;
    }
  }  
`;
const MobileNav = styled.div`
  width: 50vw;
  height: 100vh;  
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0vh;
  right: 0;
  ul {    
    li {    
      cursor: pointer;
      line-height: 7vh;
      &:first-child{        
        border-bottom: 1px solid #f1f3f5;
        span { 
          font-weight: bold;
          padding-left: 5vw;
        }
      }
      &:last-child{
        border-top: 1px solid #f1f3f5;
      }
      a {
        display: block;
        color: rgba(0, 0, 0, 1);
        width: 35vw;
        height: 7vh;
        padding-left: 5vw;
      }     
    }
  }  
`;

const Overlay = styled(motion.div)`
  position: fixed;    
  top: 0vh;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;  
`;

function Nav() {
  //햄버거 버튼 구현(mobile)
  const [isVisible, SetVisible] = useState(false);
  const onClick = () => {
    SetVisible((current) => !current);
  }
  return (
    <Wrapper>
      <NavContainer>
        <Link to = "/">
          <Logo />
        </Link>        
        <MenuContainer>
          <Menu>
            <Link to = "/about-us">회사소개</Link>
          </Menu>
          <Menu>
            <Link to ="/services">서비스</Link>
          </Menu>
          <Menu>
            <Link to = "/partners">파트너</Link>
          </Menu>
          <Menu>
            <Link to = "/contact">문의하기</Link>
          </Menu>
          <Menu>
            <Link to = "/recruit">
              <div>
                <span>채용</span>
              </div>
            </Link>
          </Menu>
        </MenuContainer>
        <MobileBtn onClick={onClick}>
          <i className="fas fa-bars"></i>
        </MobileBtn>
      </NavContainer>
      {isVisible && (
      <>
        <Overlay onClick={onClick} animate = {{ opacity: 1}} exit = {{opacity: 0}}/>  
        <MobileNav onClick = {() => SetVisible(false)}>
          <ul>
            <li><span>메뉴</span></li>
            <li><Link to = "/#top">홈</Link></li>
            <li><Link to = "/about-us">회사소개</Link></li>
            <li><Link to ="/services">서비스</Link></li>
            <li><Link to = "/partners">파트너</Link></li>
            <li><Link to = "/contact">문의하기</Link></li>
            <li><Link to = "/recruit">채용</Link></li>
          </ul>
        </MobileNav>
      </>
      )}
    </Wrapper>
  );
}

export default Nav;
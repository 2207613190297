import { Wrapper } from './RecruitPosition';

function RecruitAsk() {
  return(
    <Wrapper>
      <details>
        <summary>채용 진행 절차는 어떻게 되나요?</summary>
        <p>포지션 별로 약간의 차이는 있을 수 있지만 보통 다음과 같이 진행됩니다.</p>
        <p>서류 검토 &gt; 1차 기술 면접 &gt; 2차 대면 인터뷰 &gt; 최종 합류</p>
      </details>
      <details>
        <summary>면접 참가시 복장은 어떻게 해야하나요?</summary>
        <p>자유 복장이니 편하게 오시면 됩니다.</p>        
      </details>
    </Wrapper>
  );
}

export default RecruitAsk;
import Common from '../components/Space/Common'
import SubBanner from "../components/SubBanner";
import { ContentWrapper, Title, Line } from "./PageAboutUs";

function PageSpace() {
  return (
    <>
      <SubBanner />
      <ContentWrapper>
        <Title>공간 대관 서비스</Title>
        <Line />
        <Common />
      </ContentWrapper>      
    </>
    
  );
}

export default PageSpace;
import styled from "styled-components";
import logo from "../img/bgs/hero.png";

const Wrapper = styled.div`
  height: 96vh;
  position: relative;
  @media screen and (max-width: 500px) {
    height: 92vh;
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    height: 80vh;
  }
`;
const IntroContainer = styled.div`  
  padding: 22vh 0;  
  width: 1000px;
  margin: auto;  
  @media screen and (max-width: 500px) {
    width: 360px;
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    width: 600px;
  }  
`;
const IntroTitle = styled.div`
  padding: 3vh 0;
  opacity: 0;
  animation: Title_move 1s forwards ease;

  @keyframes Title_move {
    from {
      transform: translate(0, 0);
      opacity: 0;
    }
    to {
      transform: translate(0, -10px);
      opacity: 1;
    }
  }
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.3;
  color: #555555;  
  span {
    color: #12b886;   
  }
  @media screen and (max-width: 500px) {
    font-size: 28px;
  }
`;

const IntroOverview = styled.div`
  padding: 2vh 0;
  opacity: 0;
  animation: Title_move 1s 0.5s forwards ease;
`;
const Overview = styled.p`
  line-height: 1.6;
  color: #868E96;
`;
const IntroLogo = styled.div`
  width: 50vw;
  height: 60vh;
  background: url(${logo}) center/contain no-repeat;
  position: absolute;
  bottom: -8%;
  right: 10%;
  opacity: 0;
  animation: Logo_move 1s 0.8s forwards ease;

  @keyframes Logo_move {
    from {
      right: 5%; 
      opacity: 0;
    }
    to {
      right: 10%;
      opacity: 1;
    }
  }

  @media screen and (max-width: 500px) {    
    width: 80vw;    
    left: 10%;
    bottom: -20%;           
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {    
    bottom: -15%;
  }  
`;

function Intro() {
  return(
    <Wrapper>
      <IntroContainer>
        <IntroTitle>
          <Title><span>MCN 크리에이터</span>&nbsp;전문</Title>
          <Title>종합 솔루션 기업 크리액션</Title>          
        </IntroTitle>
        <IntroOverview>
          <Overview>업계 최장 경력의 솔루션 기업 크리액션입니다.</Overview>
          <Overview>"MCN 시장의 애로 사항을 해결합니다."</Overview>
        </IntroOverview>
        <IntroLogo />
      </IntroContainer>
    </Wrapper>
  );
}

export default Intro;
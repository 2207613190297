import InfoContainer from "../components/Contact/InfoContainer";
import SubBanner from "../components/SubBanner";
import { ContentWrapper, Title, Line } from './PageAboutUs';

function PageContact() {
  return (
    <>
      <SubBanner />
      <ContentWrapper>
        <Title>문의하기</Title>
        <Line />
        <InfoContainer />
      </ContentWrapper>      
    </>
  );
}

export default PageContact;
import ServicesList from "../components/Services/ServicesList";
import SubBanner from "../components/SubBanner";
import { ContentWrapper, Title, Line } from "./PageAboutUs";

function PageService() {
  return (
    <>
      <SubBanner />
      <ContentWrapper>
        <Title>서비스</Title>
        <Line />
        <ServicesList />
      </ContentWrapper>      
    </>
    
  );
}

export default PageService;
import { Switch, Route } from "react-router-dom";
import Home from "./Routes/Home";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import PageAboutUs from "./Routes/PageAboutUs";
import PageService from "./Routes/PageService";
import PageSpace from "./Routes/PageSpace";
import PagePartners from "./Routes/PagePartners";
import PageContact from "./Routes/PageContact";
import PageRecruit from "./Routes/PageRecruit";

function App() {
  return (
    <div>
      <Nav />
      <Switch>        
        <Route path = "/recruit">
          <PageRecruit />
        </Route>
        <Route path = "/contact">
          <PageContact />
        </Route>
        <Route path = "/partners">
          <PagePartners />
        </Route>
        <Route path = "/space">
          <PageSpace />
        </Route>
        <Route path = "/services">
          <PageService />
        </Route>
        <Route path = "/about-us">
          <PageAboutUs />
        </Route>
        <Route path = "/">
          <Home />
        </Route>
      </Switch>
      <Footer />      
    </div>
  );
}

export default App;

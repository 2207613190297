import styled from "styled-components";
import { Link } from "react-router-dom";
import CategoryContainer from "./CategoryContainer";

const Wrapper = styled.div`
  padding: 5vh 0;
  background-color: #12b886;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AboutContainer = styled.div`
  padding: 5vh 0;
  width: 1000px;
  @media screen and (max-width: 500px) {
    width: 360px;
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    width: 600px;
  }
`;
const AboutUs = styled.div`
  padding: 3vh 0;
  span {
    color: white;
    border-bottom: 2px solid white;
    line-height: 1.5;
  }  
`;
const AboutTitle = styled.h1`
  font-size: 30px;
  color: white;
  padding: 1vh 0;
  line-height: 1.2;
  @media screen and (max-width: 500px) {
    font-size: 22px;
  }
`;
const AboutOverview = styled.p`
  color: white;
  line-height: 1.5;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

const DetailBtn = styled.p`
  text-align: right;
  color: white;
`;

function About() {
  return (
  <Wrapper>
    <AboutContainer>
      <AboutUs>
        <span>ABOUT US</span>
      </AboutUs>
      <AboutTitle>"MCN 시장이 어려우신 분들의 애로사항을 너무나도 잘 알고 있습니다."</AboutTitle>
      <AboutOverview>마케팅, 기업과 크리에이터 매칭, 소프트웨어 개발 등 모든 분야의 전문가가 모였습니다.</AboutOverview>
      <CategoryContainer />
      <Link to ="/about-us">
        <DetailBtn>자세히 보기 &nbsp;&gt;</DetailBtn>
      </Link>
    </AboutContainer>
  </Wrapper>
  );
}

export default About;
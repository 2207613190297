import styled from 'styled-components'
import { Wrapper, Title, ContentContainer, Content } from './Office'
import { BookBtn } from './Office'

import semina from '../../img/logos/semina_logo_b.png'
import semina01 from '../../img/bgs/semina01.png'
import semina02 from '../../img/bgs/semina02.png'
import semina_price from '../../img/prices/semina_price.png'
import booking from '../../img/logos/booking.png'

const SeminaContainer = styled(ContentContainer)`
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 10px;
`;
const SeminaCont = styled(Content)`
  background-size: cover;
  width: 100%;
  height: 320px;   
  @media screen and (max-width: 500px) {
    background-size: contain;     
    &:first-child {      
      height: 120px;
    }
    &:last-child {
      height: 120px;
    }
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    height: 200px; 
  }
`;

function Semina() {
  return(
    <Wrapper>
      <Title>
        <img src={semina} alt="강의세미나실 로고" />
        <h1>&nbsp;강의세미나실</h1>
      </Title>
      <SeminaContainer>
        <SeminaCont src={semina01} />
        <SeminaCont src={semina02} />        
      </SeminaContainer>
      <ContentContainer>
        <Content src={semina_price} />
        <Content>
          <a href="https://booking.naver.com/booking/10/bizes/684938/items/4383989" target="blank" rel="noreferrer noopener">
            <BookBtn>              
              <p><img src={booking} alt="강의세미나실 예약" />&nbsp;네이버 예약하기</p>
            </BookBtn>
          </a>
        </Content>
      </ContentContainer>      
    </Wrapper>
  );
}

export default Semina;
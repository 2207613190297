import styled from 'styled-components';
import { Link } from 'react-router-dom';
import creaction from "../../img/logos/creaction.png";
import hackerton from "../../img/logos/hackerton.jpg";
import contactYoutuber from "../../img/logos/kreators.png";
import goteamjang from "../../img/logos/goteamjang.png";
import kcreators from "../../img/logos/koreacreatoredu_2024.png";
import office from "../../img/logos/office_logo.png";
import studio from "../../img/logos/studio_logo.png";
import semina from "../../img/logos/semina_logo.png";

const Wrapper = styled.div``;
const ServiceContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 5vh;  
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const ServiceItem = styled.li`
  width: 470px;
  margin: auto;
  padding: 1vh 0;  
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  &:hover { 
    transform: scale(1.03);
    transition: all 0.3s;
  }
  @media screen and (max-width: 500px) {
    width: 360px;
  }    
`;
const ItemContainer = styled.div`
  padding: 10px;
    
`;
const LogoImg = styled.div`
  width: 70px;
  height: 70px;
  background-image: url(${props => props.src});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  margin: auto;
`;
const OverviewContainer = styled.div`
  max-width: 430px;
  margin: auto;
  padding: 2vh 0;
`;
const ServiceName = styled.p`
  text-align: center;
  font-weight: bold;
  line-height: 1.2;
`;
const SubName = styled.p`
  text-align: center;
  color: #12b886;
  line-height: 1.8;
  font-size: 14px;
`;
const Description = styled.div`
  max-width: 360px;
  padding: 10px 0;
  margin: 2vh auto;
  p {
    font-size: 14px;
    color: #868E96;
    line-height: 1.5;
    text-align: center;
  }  
`;
const Slogan = styled.p`
  text-align: center;
  line-height: 1.5;
`;
const BottomHope = styled.h3`
  font-size: 19px;
  text-align: center;
  margin-top: 3.5vh;
  @media screen and (max-width: 1000px) {
    font-size: 22px;
    line-height: 1.5;
  }
`;
const NewService = styled.div`
  background-color: #112345;  
  width: 100%;
  margin: auto;
  margin-top: 20px;  
  padding: 7vh 0;  
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  &:hover { 
    transform: scale(1.03);
    transition: all 0.3s;
  }
  @media screen and (max-width: 500px) {
    width: 360px;
  }
  @media screen and (min-width:500px) and (max-width: 1000px) {
    width: 470px;
  }
`;
const SpaceContainer = styled.div`
  max-width: 300px;
  margin: auto;
  padding: 10px;  
  border-radius: 10px;
`;
const SpaceName = styled(ServiceName)`
  padding-top: 10px;
  font-size: 25px;
  color: #fff;
  @media screen and (max-width: 500px){
    font-size: 20px;
  }
`;
const SpaceService = styled.ul`
  max-width: 160px;
  margin: auto;
  padding-top: 15px;  
`;
const SpaceSubName = styled.li`  
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 1.5;
  color: #fff;
  img {
    width: 18px; 
    height: 18px;
    display: block;
    margin: auto;
  }
  p {    
    font-size: 20px;
    padding-left: 5px;
    &:first-child {
      width: 20%;
    }
    &:last-child {
      width: 80%;
    }
  }  
  @media screen and (max-width: 500px){
    p {
      font-size: 16px;
    }
  }
`;
const KcreatorsService = styled.div`
  width: 100%;
  margin: 20px auto;
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  &:hover { 
    transform: scale(1.03);
    transition: all 0.3s;
  }
  @media screen and (max-width: 500px) {
    width: 360px;
  }
  @media screen and (min-width:500px) and (max-width: 1000px) {
    width: 470px;
  }
`;

function ServicesList() {
  return(
    <Wrapper>
      <NewService>
        <Link to="/space">
          <ItemContainer>
            <SpaceContainer>
              <SpaceName>                
                &nbsp;공간 대관 서비스 안내
              </SpaceName>
              <SpaceService>
                <SpaceSubName>
                  <p><img src={office} alt="공유오피스 로고" /></p>
                  <p>&nbsp;공유오피스</p>
                </SpaceSubName>
                <SpaceSubName>
                  <p><img src={studio} alt="스튜디오 로고" /></p>
                  <p>&nbsp;스튜디오</p>
                </SpaceSubName>
                <SpaceSubName>
                  <p><img src={semina} alt="강의세미나실 로고" /></p>
                  <p>&nbsp;강의세미나실</p>
                </SpaceSubName>
              </SpaceService>            
            </SpaceContainer>
          </ItemContainer>
        </Link>                
      </NewService>
      <ServiceContainer>
        <ServiceItem>
          <a href ="https://www.creatoredu.net/수강신청" target="blank" rel="noreferrer noopener">
            <ItemContainer>
              <LogoImg src={creaction} />
              <OverviewContainer>
                <ServiceName>크리액션 아카데미</ServiceName>
                <SubName>유튜브 크리에이터 전문 교육/컨설팅 기관</SubName>
                <Description>
                  <p>신세대 크리에이터를 발굴하고,</p>
                  <p>그들과 함께 크리에이티브한 세상을 꿈꿉니다.</p>
                </Description>
                <Slogan>"Creactor can Creaction"</Slogan>
              </OverviewContainer>            
            </ItemContainer>
          </a>                    
        </ServiceItem>
        <ServiceItem>
          <a href ="https://www.creatoredu.net/세미나" target="blank" rel="noreferrer noopener">
            <ItemContainer>
              <LogoImg src={hackerton} />
              <OverviewContainer>
                <ServiceName>크리에이터톤</ServiceName>
                <SubName>유튜브 크리에이터 전문 대회/행사 서비스</SubName>
                <Description>
                  <p>크리에이터간 소통의 장을 마련함으로써</p>
                  <p>보다 더 크리에이티브한 세상을 꿈꿉니다.</p>
                </Description>
                <Slogan>"Creative Competition for Creactor and Company!"</Slogan>
              </OverviewContainer>            
            </ItemContainer>
          </a>         
        </ServiceItem>
        <ServiceItem>
          <a href ="https://kreators.co.kr/" target="blank" rel="noreferrer noopener">
            <ItemContainer>
              <LogoImg src={contactYoutuber} />
              <OverviewContainer>
                <ServiceName>컨택트유튜버</ServiceName>
                <SubName>유튜버, 기업 매칭 비즈니스 플랫폼</SubName>
                <Description>
                  <p>유튜버와 기업 담당자간의 비즈니스가</p>
                  <p>원활하게 진행될 수 있는 세상을 꿈꿉니다.</p>
                </Description>
                <Slogan>"Just Contact Youtuber"</Slogan>
              </OverviewContainer>            
            </ItemContainer>
          </a>          
        </ServiceItem>
        <ServiceItem>
          <a href ="https://goteamjang.com/" target="blank" rel="noreferrer noopener">
            <ItemContainer>
              <LogoImg src={goteamjang} />
              <OverviewContainer>
                <ServiceName>고팀장</ServiceName>
                <SubName>종합 광고 마케팅 대행 서비스</SubName>
                <Description>
                  <p>유튜버 및 유튜브 채널 맞춤형 광고 마케팅 솔루션을</p>
                  <p>제공함으로써 다같이 풍요로운 세상을 꿈꿉니다.</p>
                </Description>
                <Slogan>"Marketing Go Teamjang!"</Slogan>
              </OverviewContainer>            
            </ItemContainer>
          </a>          
        </ServiceItem>
      </ServiceContainer>
      <KcreatorsService>
        <a href ="https://creatoredu.kr/" target="blank" rel="noreferrer noopener">
          <ItemContainer>
            <LogoImg src={kcreators} />
            <OverviewContainer>
              <ServiceName>한국크리에이터교육센터</ServiceName>
              <SubName>크리에이터, 관련 기관 및 종사자 등을 위한 교육 단체</SubName>
              <Description>
                <p>크리에이터들의 성취에 빛을 비추고 힘을 보태며</p>
                <p>대한민국 크리에이터의 독창성과 우수성을 널리 알립니다.</p>
              </Description>
              <Slogan>"Association of the creators, by the creators, for the creators, Korea Creators Association"</Slogan>
            </OverviewContainer>
          </ItemContainer>
        </a>        
      </KcreatorsService>
      <BottomHope>
        <p>여러분들과 함께 꿈을 이뤄내겠습니다! 크리액션 일동</p>
      </BottomHope>
    </Wrapper>
  );
}

export default ServicesList;

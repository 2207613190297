import styled from "styled-components";

const Wrapper = styled.div`
  padding-bottom: 5vh;
`;
const ServiceContainer = styled.ul`
  padding: 0 20px;
  li { 
    list-style-type: disc;
    line-height: 1.8;
    span {
      font-weight: bold;
    }
    a {
      &:hover {
        font-weight: bold;
        color: #666666;
      }
    }
    & li {
      list-style: inside;
    }
  }
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

function AboutService() {
  return (
    <Wrapper>
      <ServiceContainer>
        <li>
          <span>크리액션 아카데미</span>&nbsp;<a href="https://creatoredu.net/수강신청" target="blank" rel="noreferrer noopener">(creatoredu.net/수강신청)</a>
          <ul>
            <li>크리에이터 전문 교육/컨설팅 기관</li>
          </ul>
        </li>
        <li>
          <span>크리미나</span>&nbsp;<a href="https://creatoredu.net/수강신청" target="blank" rel="noreferrer noopener">(creatoredu.net/세미나)</a>
          <ul>
            <li>크리에이티브 세미나, 전문 대회/행사 서비스</li>
          </ul>
        </li>
        <li>
          <span>크리에이터스</span>&nbsp;<a href="https://kreators.co.kr" target="blank" rel="noreferrer noopener">(kreators.co.kr)</a>
          <ul>
            <li>디지털 종합 대행 서비스</li>
          </ul>
        </li>
        <li>
          <span>고팀장</span>&nbsp;<a href="https://goteamjang.com" target="blank" rel="noreferrer noopener">(goteamjang.com)</a>
          <ul>
            <li>종합 광고 마케팅 대행 서비스</li>
          </ul>
        </li>
        <li>
          <span>한국크리에이터교육센터</span>&nbsp;<a href="https://creatoredu.kr" target="blank" rel="noreferrer noopener">(creatoredu.kr/)</a>
          <ul>
            <li>크리에이터, 관련 기관 및 종사자 등을 위한 교육 단체</li>
          </ul>
        </li>
      </ServiceContainer>
    </Wrapper>
  );
}

export default AboutService;

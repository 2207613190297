import Intro from "../components/Intro";
import About from "../components/About/About";
import Services from "../components/Services/Services";
import Partners from "../components/Partners/Partners";
import Contact from "../components/Contact/Contact";

function Home() {
  return (
    <>
      <Intro />
      <About />
      <Services />
      <Partners />
      <Contact />      
    </>
  );
}

export default Home;
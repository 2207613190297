import { Wrapper, Title, ContentContainer, Content } from './Office'
import { BookBtn } from './Office'

import studio from '../../img/logos/studio_logo_b.png'
import studio01 from '../../img/bgs/studio01.jpg'
import studio02 from '../../img/bgs/studio02.jpg'
import studio_price from '../../img/prices/studio_price.png'
import booking from '../../img/logos/booking.png'

function Studio() {
  return(
    <Wrapper>
      <Title>
        <img src={studio} alt="스튜디오 로고" />
        <h1>&nbsp;스튜디오</h1>
      </Title>
      <ContentContainer>
        <Content src={studio01} />
        <Content src={studio02} />
        <Content src={studio_price} />
        <Content>
          <a href="https://booking.naver.com/booking/10/bizes/684938/items/4383990" target="blank" rel="noreferrer noopener">
            <BookBtn>              
              <p><img src={booking} alt="스튜디오 예약" />&nbsp;네이버 예약하기</p>
            </BookBtn>
          </a>
        </Content>
      </ContentContainer>
    </Wrapper>
  );
}

export default Studio;
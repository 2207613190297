import styled from "styled-components";
import ServicesList from "./ServicesList";


const Wrapper = styled.div`
  padding: 2.5vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ServiceContainer = styled.div`
  padding: 5vh 0;
  width: 1000px;
  @media screen and (max-width: 500px) {
    width: 360px;
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    width: 600px;
  }
`;
const Service = styled.div`
  padding: 3vh 0;
  span {
    color: #12b886;
    border-bottom: 2px solid #12b886;
    line-height: 1.5;
  }
`;
const ServiceTitle = styled.h1`
  font-size: 34px;
  padding: 15px 0;
  @media screen and (max-width: 1000px) {
    font-size: 22px;
    line-height: 1.5;
  }
`;

function Services() {
  return(
  <Wrapper>
    <ServiceContainer>
      <Service>
        <span>SERVICES</span>
      </Service>
      <ServiceTitle>"기업과 크리에이터, 둘 다 고려했습니다."</ServiceTitle>
      <ServicesList />      
    </ServiceContainer>
  </Wrapper>
  );
}

export default Services;
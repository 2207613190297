import styled from "styled-components";

const Wrapper = styled.div`
  line-height: 1.5;
  padding-top: 1vh;
  div {
    padding: 1vh 0;
    p { 
      font-size: 15px; 
      @media screen and (max-width: 500px) {
        font-size: 14px;
      } 
    }    
  }
`;

function AboutOverview() {
  return (
  <Wrapper>
    <div>
      <p>크리액션은 MCN (멀티 채널 네트워크) 시장을 메인 필드로 하고 있습니다.</p>
    </div>
    <div>
      <p>아카데미를 주축으로 업계 최전선에서 급격하게 형성되는 애로사항들을 해결합니다.</p>
      <p>현재 저희가 제공하는 서비스와 브랜드는 아래와 같습니다.</p>
    </div>
  </Wrapper>
  );
}

export default AboutOverview;
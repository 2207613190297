import styled from "styled-components";
import { Link } from "react-router-dom";
import creaction from "../img/logos/creaction_logo.png";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FooterContainer = styled.div`
  padding: 3vh 0;
  width: 1000px;  
  @media screen and (max-width: 500px) {
    width: 360px;
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    width: 600px;
  }
`;
const BottomMenu = styled.ul`
  width: 300px;
  display: flex;
  justify-content: space-between;
`;
const Menu = styled.li`
  font-size: 13px;  
  position: relative;
  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: -15px;
    width: 1px;
    height: 13px;
    background-color: #e8e8e8;       
  }
  a {
    text-align: center;         
    &:hover { color: #12b886 }
  }
`;
const CreactionLogo = styled.div`
  margin: 3vh 0;
  width: 200px;
  height: 42px;
`;
const LogoImg = styled.div`
  width: 100%;
  height: 100%;
  background: url(${creaction}) center/contain no-repeat; 
`;
const Contact = styled.div`
  padding-bottom: 3vh;
  font-size: 13px;
  color: #909399;
  span {
    font-weight: bold;
  }
`;
const CompanyInfo = styled.ul``;
const Company = styled.li`
  font-size: 12px;
  color: #909399;
  line-height: 1.7;
`;

const Hr = styled.div`
  width: 1000px;
  height: 1px;
  background-color: #e8e8e8;
  margin: 3vh 0;    
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
const BottomInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Copyright = styled.div`
  font-size: 12px;
  color: #868296;
`;
const SnsContainer = styled.ul`
  width: 70px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    width: 80px;
  }
`;
const Sns = styled.li` 
  a {
    color: #868e96;
    i { 
      font-size: 20px;
      text-align: center;
    }
    &:hover {
      color: #12b886;
    }
  }
`;

function Footer() {
  return(
    <Wrapper>
      <FooterContainer>
        <Hr />
        <BottomMenu>
          <Menu>
            <Link to = "/about-us">회사소개</Link>
          </Menu>
          <Menu>
            <Link to = "/services">서비스</Link>
          </Menu>
          <Menu>
            <Link to = "/partners">파트너</Link>
          </Menu>
          <Menu>
            <Link to = "/contact">문의하기</Link>
          </Menu>          
          <Menu>
            <Link to = "/recruit">채용</Link>
          </Menu>          
        </BottomMenu>
        <CreactionLogo>
          <LogoImg />
        </CreactionLogo>
        <Contact>
          <span>고객센터 070-8779-0707/7288</span>&nbsp;&nbsp; | 평일 오전 10시 - 오후 10시          
        </Contact>
        <CompanyInfo>
          <Company>크리액션 | 사업자등록번호 230-01-26286 | 대표 최정욱</Company>
          <Company>본점 서울 강남구 논현로76길 12, 2층 |  2호점 서울 강남구 논현로79길 8, 7층</Company>          
          <Company>TEL 070-8779-0707/7288 | E-mail help@creaction.kr </Company>
          <Company>통신판매업신고 2021-서울강남-00106호</Company>
        </CompanyInfo>
        <Hr />
        <BottomInfo>
          <Copyright>&copy;크리액션. All Rights Reserved.</Copyright>
          <SnsContainer>
            <Sns>
              <a href="https://www.instagram.com/creaction_korea/" target="blank" rel="noreferrer noopener"><i aria-hidden="true" className="fa fa-instagram"></i> </a>
            </Sns>
            <Sns>
              <a href="https://www.facebook.com/creaction.like/" target="blank" rel="noreferrer noopener"><i aria-hidden="true" className="fa fa-facebook"></i> </a>
            </Sns>
            <Sns>
              <a href="https://www.youtube.com/channel/UCGYM_O16ugFdjNZl4yOexIA" target="blank" rel="noreferrer noopener"><i aria-hidden="true" className="fa fa-youtube-play"></i> </a>
            </Sns>
          </SnsContainer>
        </BottomInfo>
      </FooterContainer>
    </Wrapper>
  );
}

export default Footer;
import banner from '../img/bgs/submenu_bg.png';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 500px;  
  @media screen and (max-width: 500px) {
    height: 300px;
  }
  @media screen and (min-width: 500px) and (max-width: 1300px) {
    height: 350px;
    //4k 높이 깨짐 방지
  }
`;

const Banner = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url(${banner}) center/cover no-repeat;
`;

function SubBanner() {
  return (
    <Wrapper>      
      <Banner />
    </Wrapper>
  );
}

export default SubBanner;
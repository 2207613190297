import styled from 'styled-components'
import office from '../../img/logos/office_logo_b.png'
import office01 from '../../img/bgs/office01.jpeg'
import office02 from '../../img/bgs/office02.jpg'
import office_price from '../../img/prices/office_price.png'
import booking from '../../img/logos/booking.png'

export const Wrapper = styled.div`
  margin: 3vh auto;
`;
export const Title = styled.div`
  width: 300px;   
  padding: 3vh 0;
  display: flex;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
  }
  h1 {
    font-size: 20px; 
  }
`;
export const ContentContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1,1fr);
  }
`;
export const Content = styled.li`
  height: 550px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  &:first-child {
    background-position: center center;
  }
  &:nth-of-type(3) {
    width: 550px;    
    background-size: cover;
  }  
  @media screen and (max-width:1000px) {
    height: 360px;
    &:nth-of-type(3) {
      width: 360px;      
      background-size: cover;
    }
    &:last-child {
      height: 200px;
    }
  }
`;
export const BookBtn = styled.div`
  width: 60%;
  margin: 25vh auto; 
  height: 50px;
  border-radius: 5px;  
  background-color:#2db400;  
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  p { 
    font-size: 18px;    
    font-weight: 700; 
  }
  img {
    width: 18px;
    height: 18px;
  }
  &:hover { box-shadow: 1px 2px 3px rgba(0,0,0,0.3);}
  @media screen and (max-width: 1000px) {
    margin: 7vh auto;
    p {
      font-size: 15px;
    }
  }
`;

function Office() {
  return(
    <Wrapper>
      <Title>
        <img src={office} alt="공유오피스 로고" />
        <h1>&nbsp;공유오피스</h1>
      </Title>
      <ContentContainer>
        <Content src={office01} />
        <Content src={office02} />
        <Content src={office_price} />
        <Content>
          <a href="https://booking.naver.com/booking/10/bizes/684938/items/4383991" target="blank" rel="noreferrer noopener">
            <BookBtn>              
              <p><img src={booking} alt="공유오피스 예약" />&nbsp;네이버 예약하기</p>
            </BookBtn>
          </a>
        </Content>
      </ContentContainer>
    </Wrapper>
  );
}

export default Office;
import styled from "styled-components";
import PartnerList from "./PartnersList";

const Wrapper = styled.div`
  padding: 5vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f3f5;
`;
const PartnerContainer = styled.div`
  padding: 5vh 0;
  width: 1000px;
  @media screen and (max-width: 500px) {
    width: 360px;
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    width: 600px;
  }
`;
const Partner = styled.div`
  padding: 3vh 0;
  span {
    color: #12b886;
    border-bottom: 2px solid #12b886;
    line-height: 1.5;
  }
`;
const PartnerTitle = styled.h1`
  font-size: 34px;
  line-height: 1.3;
  @media screen and (max-width: 1000px) {
    font-size: 22px;
    line-height: 1.5;
  }
`;
const PartnerOverview = styled.p`
  color: #495057;
  line-height: 1.8;
  @media screen and (max-width: 1000px) {
    font-size: 14px;    
  }
`;

function Partners() {
  return(
    <Wrapper>
      <PartnerContainer>
        <Partner>
          <span>PARTNERS</span>
        </Partner>
        <PartnerTitle>"우리의 소중한 고객입니다."</PartnerTitle>
        <PartnerOverview>약 200개 이상의 정부 기관, 민간 기업, 학교, 단체들과 함께 했습니다.</PartnerOverview>
        <PartnerList />
      </PartnerContainer>
    </Wrapper>
  );
}

export default Partners;
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 1.5vh 0;
  margin-bottom: 30px;
  details {
    padding: 10px 0;
    font-size: 14px;
    line-height: 1.6;
    border-bottom: 1px solid #f1f3f5;        
    summary {
      padding: 0.5vh 0;
      cursor: pointer;      
    }
    ul {
      padding: 1vh 0;
      li {
        padding-left: 1vw;
        list-style: none;
        span { font-weight: bold; }
        ul {
          padding: 0;
          list-style: inside;
        }
      }
    }     
    p {
      font-size: 14px;      
      padding: 0.5vh 0;
      &:last-child { margin-bottom: 1vh; }
    }       
  }
`;

function RecruitPosition() {
  return(
    <Wrapper>
      <details>
        <summary>🎉 운영팀</summary>
        <ul>
          <li><span>주요업무</span></li>
          <li>- 사내 전 서비스 운영
              <ul>
                  <li>- 온라인/소프트웨어 서비스 운영</li>
                  <li>- 오프라인 공간/매장 운영</li>
                  <li>- 행사 프로모션 기획/운영</li>
                  <li>- 교육 기획/운영</li>
                  <li>- 매니지먼트 기획/운영</li>
              </ul>
          </li>
          <li>- CS 상담</li>
        </ul>
        <ul>
          <li><span>자격 요건</span></li>
          <li>- MCN 시장 이해도</li>
          <li>- 커뮤니케이션 Skill 보유</li>
          <li>- 영어 가능 (중급 이상)</li>
          <li>- 문서/디자인 툴 활용 가능 (MS Office, Adobe 등)</li>
          <li>- CS 가능</li>
        </ul>
        <ul>
          <li><span>우대 사항</span></li>
          <li>- 전/현 유튜버</li>
          <li>- 스마트폰 (앱) 활용도가 높으신 분</li>
          <li>- 한국어, 영어 외 1 외국어 능통 (중국어, 아랍어 등)</li>
          <li>- 해외연수 경험</li>
          <li>- 관련 경력자 (CS 등)</li>
        </ul>      
      </details>
      <details>
        <summary>🎨 디자인팀</summary>
        <ul>
          <li><span>주요 업무</span></li>
          <li>- 크리액션 서비스 디자인 및 운영</li>          
        </ul>
        <ul>
          <li><span>자격 요건</span></li>
          <li>- 플랫폼 및 컨텐츠 디자인 기획 및 제작 가능</li>
          <li>- 반응형 웹 UI/UX 디자인 및 HTML/CSS 퍼블리싱 가능</li>
          <li>- 프로토타입 디자인 및 브랜드 디자인 에셋 제작 가능</li>
          <li>- 사용성 향상을 위한 경험자 기반 화면설계 및 디자인 개선</li>          
        </ul>
        <ul>
          <li><span>우대 사항</span></li>
          <li>- 전/현 유튜버</li>
          <li>- 웹 표준 코딩, 크로스 브라우징에 대한 경험 및 이해</li>
          <li>- 디자인 구현을 위한 플랫폼 이해도</li>
          <li>- 웹 및 모바일 앱 등 디자인 및 개발 경험자</li>
          <li>- 일러스트레이터, 인디자인 등 포토샵 외 디자인 툴 활용 가능자</li>
        </ul>     
      </details>
      <details>
        <summary>💻 개발팀</summary>
        <ul>
          <li><span>주요 업무</span></li>
          <li>- 크리액션 서비스 개발 및 운영</li>          
        </ul>
        <ul>
          <li><span>자격 요건</span></li>
          <li>- Vue.js 혹은 React.js 개발 2년 이상 혹은 그에 준하는 실력</li>
          <li>- Node.js 개발 2년 이상 혹은 그에 준하는 실력</li>
          <li>- RDB 사용 경험</li>
          <li>- 클라우드 서비스 사용 경험</li>          
        </ul>
        <ul>
          <li><span>우대 사항</span></li>
          <li>- 전/현 유튜버</li>
          <li>- TDD (Test Driven Development) 개발 경험</li>
          <li>- AWS 사용 경험</li>
          <li>- Dokcer 사용 경험</li>
          <li>- 서비스를 구축하고 운영 해본 경험이 있는 분</li>
        </ul>     
      </details>
      <details>
        <summary>🤝 세일즈/마케팅팀</summary>
        <ul>
          <li><span>주요업무</span></li>
          <li>- 사내 전 서비스 영업/마케팅
              <ul>
                  <li>- B2B, B2G 광고/협찬 영업</li>
                  <li>- 이벤트/행사 퍼포먼스 기획</li>
                  <li>- 국내/외 사업 확장</li>
                  <li>- 온/오프라인 마케팅 프로모션 기획</li>
                  <li>- 기타 제휴</li>
              </ul>
          </li>          
        </ul>
        <ul>
          <li><span>자격 요건</span></li>
          <li>- MCN 시장 이해도</li>
          <li>- 커뮤니케이션 Skill 보유</li>
          <li>- 한국어,영어 외 1 외국어 가능 (중국어,아랍어 등)</li>
          <li>- 문서/디자인 툴 활용 가능 (MS Office, Adobe 등)</li>
          <li>- 1종 보통 면허증 소지자</li>
        </ul>
        <ul>
          <li><span>우대 사항</span></li>
          <li>- 전/현 유튜버</li>
          <li>- 스마트폰 (앱) 활용도가 높으신 분</li>         
          <li>- 해외연수 경험자</li>
          <li>- 관련 경력자 (AE 등)</li>
        </ul>       
      </details>
    </Wrapper>
  );
}

export default RecruitPosition;
import styled from "styled-components";
import Overview from '../components/About/AboutOverview';
import CategoryContainer from "../components/About/CategoryContainer";
import ServiceContainer from "../components/About/AboutService";
import SubBanner from "../components/SubBanner";

export const ContentWrapper = styled.div`  
  padding: 5vh 0;
  width: 1000px;
  margin: auto;
  @media screen and (max-width: 500px) {
    width: 360px;
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    width: 600px;
  }
`;
export const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 400px;
  color: #ffffff;
  /* border-bottom: 2px solid #ffffff;
  line-height: 1.8; 
  만약, 글자 아래 사이즈 정도만 라인이 있길 원한다면, 활용*/
  @media screen and (max-width: 500px) {
    font-size: 24px;
    top: 220px;
  }
  @media screen and (min-width: 500px) and (max-width: 1300px) {
    top: 270px;
    //4k 높이 깨짐 방지
  }  
`;
export const Line = styled.div`
  max-width: 1000px;
  width: 100%;
  height: 1.5px;
  background-color: #fff;
  border-radius: 10px;
  position: absolute;  
  top: 460px;
  @media screen and (max-width: 500px) {
    width: 360px;
    top: 270px;
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    width: 600px;    
  }
  @media screen and (min-width: 500px) and (max-width: 1300px) {
    top: 320px;
    //4k 높이 깨짐 방지
  } 
`;

function PageAboutUs() {
  return (
    <>     
      <SubBanner />     
      <ContentWrapper>
        <Title>회사 소개</Title>
        <Line />       
        <Overview />
        <CategoryContainer />      
        <ServiceContainer />       
      </ContentWrapper>          
    </>
    
  );
}

export default PageAboutUs;
import styled from "styled-components";
import InfoContainer from './InfoContainer';


const Wrapper = styled.div`
  padding: 5vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContactContainer = styled.div`
  padding: 5vh 0;
  width: 1000px;  
  @media screen and (max-width: 500px) {
    width: 360px;
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    width: 600px;
  }
`;
const ContactUs = styled.div`
  padding: 3vh 0;
  span {
    color: #12b886;
    border-bottom: 2px solid #12b886;
    line-height: 1.5;
  }
`;
const ContactTitle = styled.h1`
  font-size: 34px;
  line-height: 1.3;
  @media screen and (max-width: 1000px) {
    font-size: 25px;
    line-height: 1.5;
  }
`;
const ContactOverview = styled.p`
  color: #495057;
  line-height: 1.8;
`;
const Hr = styled.div`
  width: 1000px;
  height: 1px;
  background-color: #e8e8e8;
  margin: 3vh 0;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

function Contact() {
  return (
    <Wrapper>
      <ContactContainer>
        <ContactUs>
          <span>CONTACT US</span>
        </ContactUs>
        <ContactTitle>"궁금한 점이 있으신가요?"</ContactTitle>
        <ContactOverview>무엇이든 편하게 문의해주세요. 최대한 빠른 시일내에 답변드리도록 하겠습니다.</ContactOverview>
        <Hr />
        <InfoContainer/>       
      </ContactContainer>
    </Wrapper>
  );
}

export default Contact;
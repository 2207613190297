import styled from "styled-components";

const Wrapper = styled.div`
  padding: 1vh 0;
  margin-bottom: 30px;
`;
const Text = styled.div`
  font-size: 15px;
  line-height: 1.8;
  margin-bottom: 1vh;
`;
const BtnContainer = styled.div`
  max-width: 350px;
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: 500px) {
    max-width: 360px;
    flex-direction: column;
  }  
`;
const Email = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  height: 35px;
  border-radius: 4px;
  font-weight: 700;
  border: 1px solid #ced4da;
  background-color: #fff;  
  p { 
    font-size: 14px;
    color: #343a40; 
  }
  svg { font-size: 14px; }
  &:hover {
    box-shadow: 1px 2px 3px rgba(0,0,0,0.3);
  }
  @media screen and (max-width: 500px) {    
    margin: 0.5vh 0;
  }
`;
const Kakao = styled(Email)`
  border: 0;
  background-color: #f7e600;
`;


function RecruitHowTo() {
  return(
    <Wrapper>
      <Text>각 포지션에 대한 공고 내용을 확인하신 후 자유형식으로 자신을 소개하는 내용과 포트폴리오, 희망 연봉을 꼭! 같이 작성해서 아래 방법으로 연락주시면 됩니다.</Text>
      <BtnContainer>
        <a href="mailto:help@creaction.kr" target="blank" rel="noreferrer noopener">
          <Email>
            <i className="icon anticon anticon-mail"><svg viewBox="64 64 896 896" data-icon="mail" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 110.8V792H136V270.8l-27.6-21.5 39.3-50.5 42.8 33.3h643.1l42.8-33.3 39.3 50.5-27.7 21.5zM833.6 232L512 482 190.4 232l-42.8-33.3-39.3 50.5 27.6 21.5 341.6 265.6a55.99 55.99 0 0 0 68.7 0L888 270.8l27.6-21.5-39.3-50.5-42.7 33.2z"></path></svg></i>
            <p>&nbsp;&nbsp;이메일로 지원하기</p>
          </Email>
        </a>        
        <a href="http://pf.kakao.com/_xbxaSpM/chat" target="blank" rel="noreferrer noopener">
          <Kakao>
            <i className="icon anticon anticon-message"><svg viewBox="64 64 896 896" data-icon="message" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M464 512a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm200 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm-400 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm661.2-173.6c-22.6-53.7-55-101.9-96.3-143.3a444.35 444.35 0 0 0-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6.3-119.3 12.3-174.5 35.9a445.35 445.35 0 0 0-142 96.5c-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9A449.4 449.4 0 0 0 112 714v152a46 46 0 0 0 46 46h152.1A449.4 449.4 0 0 0 510 960h2.1c59.9 0 118-11.6 172.7-34.3a444.48 444.48 0 0 0 142.8-95.2c41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5.3-60.9-11.5-120-34.8-175.6zm-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-.6 99.6-39.7 192.9-110.1 262.7z"></path></svg></i>
            <p>&nbsp;&nbsp;카카오톡으로 지원하기</p>
          </Kakao>
        </a>        
      </BtnContainer>
    </Wrapper>
  );
}

export default RecruitHowTo;
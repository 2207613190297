import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 5vh 0;
  h4 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  div {    
    margin-bottom: 30px;
    line-height: 1.8;
    p { 
      font-size: 15px; 
      @media screen and (max-width: 500px) {
        font-size: 14px;
      } 
    }       
  }
`;

function RecruitIntro() {
  return (
    <Wrapper>
      <h4>크리액션과 함께할 인재를 모집합니다! 🎉</h4>
      <div>
        <p>안녕하세요. 크리액션 대표원장 최정욱입니다.</p>
        <p>저희 크리액션(CREACTION)은 유튜브 크리에이터 전문 아카데미/체험/컨설팅 서비스를 제공하고 있습니다.</p>
      </div>
      <div>
        <p>오래전부터 유튜브 크리에이터 관련 행사 / 캠프 / 교육 / 멘토링 / 컨설팅 / 유튜브채널종합대행 등</p>
        <p>다양한 역할을 선도 및 수행해왔으며, 국내에서 관련 업계 최장 경력을 보유하고 있습니다.</p>
      </div>
      <div>
        <p>도전 유튜버, 체크 유튜버 등의 맞춤형 교육, 컨설팅 프로그램을 통해 신세대 크리액터(CREACTOR)를 발굴하고,</p>
        <p>그들의 끼와 재능이 담긴 콘텐츠를 제작하고 많은 사람들에게 공유하면서 자생할 수 있도록 지원하고 있습니다.</p>
      </div>
      <div>
        <p>크리에이티브한 생각은 누구나 하지만 그것을 실제로 만들어내기는 어렵습니다.</p>
        <p>저희 크리액션은 직접 창의적인 콘텐츠를 만들어내는 “액션을 하자”란 의미를 담아</p>
        <p>“창의적인”을 뜻하는 Creative를 합쳐 지금의 CREACTION을 만들었습니다.</p>
        <p>또한 “깨다”라는 의미의 “Crack”과 발음이 비슷해 “누군가를 깨우자”는 취지도 함께 담겨있습니다.</p>
      </div>
      <div>
        <p>최근 언택트, 비대면 시대가 도래하면서 가장 비주얼 측면이 강한 유튜브의 관심은 뜨겁게 치솟았습니다.</p>
        <p>이에 많은 이들이 유튜브 교육의 필요성을 인지하였고, 덕분에 크리액션은 급성장했습니다.</p>
      </div>
      <div>
        <p>오랜 기간 축적해온 크리액션만의 탄탄한 시장 인프라와 노하우를 기반으로 회사를 더</p>
        <p>빠르게 성장시키고자 합니다. 저희가 더 빠르게 성장하기 위해서는 실력있는 분들의 도움이 꼭 필요합니다.</p>
        <p>그래서 귀중한 여러분들을 크리액션으로 모셔가고자 이렇게 채용 공고를 올리게 되었습니다.</p>
      </div>
      <div>
        <p>여전히 부족한 점이 많은 저희지만 앞으로 저희가 MCN 시장에서 차지할 잠재력을 보고 같이 키워나갈 수 있으면 좋겠습니다.</p>
      </div>
    </Wrapper>
  );
}

export default RecruitIntro;
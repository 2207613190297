import styled from 'styled-components';
import seoul from "../../img/logos/seoul.png";
import seoulEdu from "../../img/logos/krlr.png";
import sba from "../../img/logos/sba.png";
import seoulC from "../../img/logos/seoul_c.png";
import kbsMedia from "../../img/logos/kbs_media.png";
import kmca from "../../img/logos/kmca.png";
import cklg from "../../img/logos/cklg.png";
import defense from "../../img/logos/defense.png";
import kyunggi from "../../img/logos/kyunggi.png";
import siheung from "../../img/logos/siheung.png";
import goyang from "../../img/logos/goyang.png";
import ejb from "../../img/logos/ejb.png";
import coex from "../../img/logos/coex.png";
import bexco from "../../img/logos/bexco.png";
import ys from "../../img/logos/ys.png";
import seoulArt from "../../img/logos/seoul_art.png";

const Wrapper = styled.div``;
const PartnersContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 5vh;
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
const PartnerItem = styled.li`
  width: 167px;
  height: 95px;
  margin: 1vh 0;  
`;
const SeoulImg = styled.div`
  width: 100%;
  height: 100%;
  background: url(${seoul}) center/contain no-repeat;
`;
const SeoulEduImg = styled(SeoulImg)`
  background: url(${seoulEdu}) center/contain no-repeat;
`;
const SbaImg = styled(SeoulImg)`
  background: url(${sba}) center/contain no-repeat;
`;
const SeoulCImg = styled(SeoulImg)`
  background: url(${seoulC}) center/contain no-repeat;
`;
const KbsMediaImg = styled(SeoulImg)`
  background: url(${kbsMedia}) center/contain no-repeat;
`;
const KmcaImg = styled(SeoulImg)`
  background: url(${kmca}) center/contain no-repeat;
`;
const CklgImg = styled(SeoulImg)`
background: url(${cklg}) center/contain no-repeat;
`;
const DefenseImg = styled(SeoulImg)`
background: url(${defense}) center/contain no-repeat;
`;
const KyunggiImg = styled(SeoulImg)`
background: url(${kyunggi}) center/contain no-repeat;
`;
const SiheungImg = styled(SeoulImg)`
background: url(${siheung}) center/contain no-repeat;
`;
const GoyangImg = styled(SeoulImg)`
background: url(${goyang}) center/contain no-repeat;
`;
const EjbImg = styled(SeoulImg)`
background: url(${ejb}) center/contain no-repeat;
`;
const CoexImg = styled(SeoulImg)`
background: url(${coex}) center/contain no-repeat;
`;
const BexcoImg = styled(SeoulImg)`
background: url(${bexco}) center/contain no-repeat;
`;
const YsImg = styled(SeoulImg)`
background: url(${ys}) center/contain no-repeat;
`;
const SeoulArtImg = styled(SeoulImg)`
background: url(${seoulArt}) center/contain no-repeat;
`;

function PartnersList() {
  return(
    <Wrapper>
      <PartnersContainer>
        <PartnerItem>
          <SeoulImg />
        </PartnerItem>
        <PartnerItem>
          <SeoulEduImg />
        </PartnerItem>
        <PartnerItem>
          <SbaImg />
        </PartnerItem>
        <PartnerItem>
          <SeoulCImg />
        </PartnerItem>
        <PartnerItem>
          <KbsMediaImg />
        </PartnerItem>
        <PartnerItem>
          <KmcaImg />
        </PartnerItem>          
        <PartnerItem>
          <CklgImg />
        </PartnerItem>
        <PartnerItem>
          <DefenseImg />
        </PartnerItem>
        <PartnerItem>
          <KyunggiImg />
        </PartnerItem>
        <PartnerItem>
          <SiheungImg />
        </PartnerItem>
        <PartnerItem>
          <GoyangImg />
        </PartnerItem>
        <PartnerItem>
          <EjbImg />
        </PartnerItem>
        <PartnerItem>
          <CoexImg />
        </PartnerItem>
        <PartnerItem>
          <BexcoImg />
        </PartnerItem>
        <PartnerItem>
          <YsImg />
        </PartnerItem>
        <PartnerItem>
          <SeoulArtImg />
        </PartnerItem>
      </PartnersContainer>
    </Wrapper>
  );
}

export default PartnersList;
import styled from "styled-components";
import creaction from "../../img/logos/creaction_big.png";
import contactYoutuber from "../../img/logos/contactyoutuber.png";
import goteamjang from "../../img/logos/goteamjang.png";
import kreators from "../../img/logos/kreators.png";
import hackerton from "../../img/logos/hackerton.jpg";


const Wrapper = styled.ul`  
  display:grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
  margin: 5vh auto;
  @media screen and (max-width: 500px) {
   grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
   grid-template-columns: repeat(3, 1fr);
  }
`;
const Category = styled.li`
  padding: 12px 9px;
  height: 120px;
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  position: relative;  
  svg {
    width: 20px;
    height: 20px;
  }
  .frontSide {
    p {
      font-size: 15px;
      text-align: right;
      padding-right: 10px;
      line-height: 1.5;
      &:nth-of-type(1) {
        padding-top: 10px;
      }    
    }
  }    
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);    
  }
  &:hover .frontSide {
    transform: scale(0);
  }
  @media screen and (max-width: 500px) {
    height: 130px;
    p { font-size: 16px; }  
  }
`;

const OverSide = styled.div`
  position: absolute;  
  top: 30%;
  z-index: -1;  
  width: 130px;  
  height: 75px;
  .hackerton {
    background-size: cover;
  }  
  p {
    padding-left: 20px;
    padding-top: 10px;     
    text-align: center;
    font-size: 12px;
    color: #112345;
    &.fontRed {
      color: #b80009;
    }    
  }     
  @media screen and (max-width: 500px) {
    width: 150px;  
    height: 80px;    
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    width: 170px;  
    height: 77px;    
  }
  
`;
const LogoImg = styled.div`
  width: 50%;
  height: 50%;
  background-image: url(${props => props.src});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
   
`;

function CategoryContainer() {
  return (
    <Wrapper>
      <Category>
        <a href="https://creatoredu.net" target="blank" rel="noreferrer noopener">
          <i className="icon anticon anticon-bulb">
            <svg viewBox="64 64 896 896" data-icon="bulb" width="1em" height="1em" fill="currentColor" aria-hidden="true">
              <path d="M632 888H392c-4.4 0-8 3.6-8 8v32c0 17.7 14.3 32 32 32h192c17.7 0 32-14.3 32-32v-32c0-4.4-3.6-8-8-8zM512 64c-181.1 0-328 146.9-328 328 0 121.4 66 227.4 164 284.1V792c0 17.7 14.3 32 32 32h264c17.7 0 32-14.3 32-32V676.1c98-56.7 164-162.7 164-284.1 0-181.1-146.9-328-328-328zm127.9 549.8L604 634.6V752H420V634.6l-35.9-20.8C305.4 568.3 256 484.5 256 392c0-141.4 114.6-256 256-256s256 114.6 256 256c0 92.5-49.4 176.3-128.1 221.8z"></path>
            </svg>
          </i>
          <div className="frontSide">
            <p>국내외 MCN 시장</p>
            <p>연구 개발</p>
          </div>
          <OverSide>
            <LogoImg src={creaction} />
            <p>바로가기 &gt;&gt;</p>          
          </OverSide>
        </a>        
      </Category>
      <Category>
        <a href="https://creatoredu.net/수강신청" target="blank" rel="noreferrer noopener">
          <i className="icon anticon anticon-edit"><svg viewBox="64 64 896 896" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg></i>
          <div className="frontSide">
            <p>크리에이터(유튜브)</p>        
            <p>교육/컨설팅</p>
          </div>
          <OverSide>
            <LogoImg src={creaction} />
            <p>바로가기 &gt;&gt;</p>
          </OverSide>
        </a>        
      </Category>
      <Category>
        <a href="https://creatoredu.net/세미나" target="blank" rel="noreferrer noopener">
          <i className="icon anticon anticon-link"><svg viewBox="64 64 896 896" data-icon="link" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M574 665.4a8.03 8.03 0 0 0-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 0 0-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 0 0 0 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 0 0 0 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 0 0-11.3 0L372.3 598.7a8.03 8.03 0 0 0 0 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z"></path></svg></i>
          <div className="frontSide">
            <p>기업&크리에이터</p>
            <p>매칭 서비스</p>
          </div>        
          <OverSide>
            <LogoImg src={contactYoutuber} />
            <p className="fontRed">바로가기 &gt;&gt;</p>
          </OverSide>
        </a>
      </Category>
      <Category>
        <a href="https://goteamjang.com" target="blank" rel="noreferrer noopener">
          <i className="icon anticon anticon-youtube"><svg viewBox="64 64 896 896" data-icon="youtube" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M960 509.2c0-2.2 0-4.7-.1-7.6-.1-8.1-.3-17.2-.5-26.9-.8-27.9-2.2-55.7-4.4-81.9-3-36.1-7.4-66.2-13.4-88.8a139.52 139.52 0 0 0-98.3-98.5c-28.3-7.6-83.7-12.3-161.7-15.2-37.1-1.4-76.8-2.3-116.5-2.8-13.9-.2-26.8-.3-38.4-.4h-29.4c-11.6.1-24.5.2-38.4.4-39.7.5-79.4 1.4-116.5 2.8-78 3-133.5 7.7-161.7 15.2A139.35 139.35 0 0 0 82.4 304C76.3 326.6 72 356.7 69 392.8c-2.2 26.2-3.6 54-4.4 81.9-.3 9.7-.4 18.8-.5 26.9 0 2.9-.1 5.4-.1 7.6v5.6c0 2.2 0 4.7.1 7.6.1 8.1.3 17.2.5 26.9.8 27.9 2.2 55.7 4.4 81.9 3 36.1 7.4 66.2 13.4 88.8 12.8 47.9 50.4 85.7 98.3 98.5 28.2 7.6 83.7 12.3 161.7 15.2 37.1 1.4 76.8 2.3 116.5 2.8 13.9.2 26.8.3 38.4.4h29.4c11.6-.1 24.5-.2 38.4-.4 39.7-.5 79.4-1.4 116.5-2.8 78-3 133.5-7.7 161.7-15.2 47.9-12.8 85.5-50.5 98.3-98.5 6.1-22.6 10.4-52.7 13.4-88.8 2.2-26.2 3.6-54 4.4-81.9.3-9.7.4-18.8.5-26.9 0-2.9.1-5.4.1-7.6v-5.6zm-72 5.2c0 2.1 0 4.4-.1 7.1-.1 7.8-.3 16.4-.5 25.7-.7 26.6-2.1 53.2-4.2 77.9-2.7 32.2-6.5 58.6-11.2 76.3-6.2 23.1-24.4 41.4-47.4 47.5-21 5.6-73.9 10.1-145.8 12.8-36.4 1.4-75.6 2.3-114.7 2.8-13.7.2-26.4.3-37.8.3h-28.6l-37.8-.3c-39.1-.5-78.2-1.4-114.7-2.8-71.9-2.8-124.9-7.2-145.8-12.8-23-6.2-41.2-24.4-47.4-47.5-4.7-17.7-8.5-44.1-11.2-76.3-2.1-24.7-3.4-51.3-4.2-77.9-.3-9.3-.4-18-.5-25.7 0-2.7-.1-5.1-.1-7.1v-4.8c0-2.1 0-4.4.1-7.1.1-7.8.3-16.4.5-25.7.7-26.6 2.1-53.2 4.2-77.9 2.7-32.2 6.5-58.6 11.2-76.3 6.2-23.1 24.4-41.4 47.4-47.5 21-5.6 73.9-10.1 145.8-12.8 36.4-1.4 75.6-2.3 114.7-2.8 13.7-.2 26.4-.3 37.8-.3h28.6l37.8.3c39.1.5 78.2 1.4 114.7 2.8 71.9 2.8 124.9 7.2 145.8 12.8 23 6.2 41.2 24.4 47.4 47.5 4.7 17.7 8.5 44.1 11.2 76.3 2.1 24.7 3.4 51.3 4.2 77.9.3 9.3.4 18 .5 25.7 0 2.7.1 5.1.1 7.1v4.8zM423 646l232-135-232-133z"></path></svg></i>
          <div className="frontSide">
            <p>종합 광고 마케팅</p>
            <p>기획/대행</p>
          </div>        
          <OverSide>
            <LogoImg src={goteamjang} />
            <p className="fontRed">바로가기 &gt;&gt;</p>
          </OverSide>
        </a>
      </Category>
      <Category>
        <a href="https://goteamjang.com" target="blank" rel="noreferrer noopener">
          <i className="icon anticon anticon-code"><svg viewBox="64 64 896 896" data-icon="code" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M516 673c0 4.4 3.4 8 7.5 8h185c4.1 0 7.5-3.6 7.5-8v-48c0-4.4-3.4-8-7.5-8h-185c-4.1 0-7.5 3.6-7.5 8v48zm-194.9 6.1l192-161c3.8-3.2 3.8-9.1 0-12.3l-192-160.9A7.95 7.95 0 0 0 308 351v62.7c0 2.4 1 4.6 2.9 6.1L420.7 512l-109.8 92.2a8.1 8.1 0 0 0-2.9 6.1V673c0 6.8 7.9 10.5 13.1 6.1zM880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656z"></path></svg></i>
          <div className="frontSide">
            <p>디지털 종합</p>
            <p>기획/대행</p>
          </div>        
          <OverSide>
            <LogoImg src={kreators} />
            <p className="fontRed">바로가기 &gt;&gt;</p>
          </OverSide>
        </a>        
      </Category>
      <Category>
        <a href="https://creatoredu.net/세미나" target="blank" rel="noreferrer noopener">
          <i className="icon anticon anticon-notification"><svg viewBox="64 64 896 896" data-icon="notification" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M880 112c-3.8 0-7.7.7-11.6 2.3L292 345.9H128c-8.8 0-16 7.4-16 16.6v299c0 9.2 7.2 16.6 16 16.6h101.7c-3.7 11.6-5.7 23.9-5.7 36.4 0 65.9 53.8 119.5 120 119.5 55.4 0 102.1-37.6 115.9-88.4l408.6 164.2c3.9 1.5 7.8 2.3 11.6 2.3 16.9 0 32-14.2 32-33.2V145.2C912 126.2 897 112 880 112zM344 762.3c-26.5 0-48-21.4-48-47.8 0-11.2 3.9-21.9 11-30.4l84.9 34.1c-2 24.6-22.7 44.1-47.9 44.1zm496 58.4L318.8 611.3l-12.9-5.2H184V417.9h121.9l12.9-5.2L840 203.3v617.4z"></path></svg></i>
          <div className="frontSide">
            <p>행사 • 네트워크</p>
            <p>기획/대행</p>
          </div>          
          <OverSide>
            <LogoImg src={hackerton} className="hackerton"/>
            <p>바로가기 &gt;&gt;</p>
          </OverSide>
        </a>        
      </Category>
    </Wrapper>
  )
}

export default CategoryContainer;

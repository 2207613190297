import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 500px;
  padding: 2vh 0;
  @media screen and (max-width: 500px) {
    font-size: 14.4px;
  }
`;
const ContactsInfo = styled.div`
  padding: 2vh 0;
`;
const Contacts = styled.ul``;
const ContactItem = styled.li`
  color: #495057;
  line-height: 1.5;
  font-size: 15px;   
  span {
    color: #212529; 
    font-weight: bold; 
    line-height: 1.8;
  }    
`;
const AskChatInfo = styled.div`
  padding: 2vh 0;
`;
const KakaoContainer = styled.ul` 
`;
const KakaoItem = styled(ContactItem)`
  display: flex;
  align-items: center;   
`;
const KakaoBtn = styled.div`
  width: 165px;  
  margin: 2vh 0; 
  height: 35px;
  border-radius: 5px;
  background-color: #F7E600;  
  display: flex;
  justify-content: center;
  align-items: center;
  color: #593607;
  svg { font-size: 14px; }
  p { 
    font-size: 14px;    
    font-weight: 700; 
  }
  &:hover { box-shadow: 1px 2px 3px rgba(0,0,0,0.3);}
`;
const LocationInfo = styled.div`
  padding: 2vh 0;
`;
const AddressContainer = styled.ul``;
const Address = styled(ContactItem)`  
  &:nth-of-type(3), &:nth-of-type(5) {
    width: 180px;
  }
`;
const NaverBtn = styled(KakaoBtn)`
  width: 100%;
  background-color:#2db400;
  color: #ffffff;
`;

function InfoContainer() {
  return(
    <Wrapper>
      <ContactsInfo>
        <Contacts>
          <ContactItem>
            <span>고객센터</span>
          </ContactItem>
          <ContactItem>help@creaction.kr</ContactItem>
          <ContactItem>070-8779-0707/7288 | 평일 오전 10시 - 오후 10시</ContactItem>
        </Contacts>
      </ContactsInfo>
      <AskChatInfo>
        <KakaoContainer>
          <KakaoItem>
            <span>문의하기</span>
          </KakaoItem>              
          <KakaoItem>
            <a href="http://pf.kakao.com/_xbxaSpM/chat" target="blank" rel="noreferrer noopener">            
              <KakaoBtn>
                <i className="icon anticon anticon-message"><svg viewBox="64 64 896 896" data-icon="message" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M464 512a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm200 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm-400 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm661.2-173.6c-22.6-53.7-55-101.9-96.3-143.3a444.35 444.35 0 0 0-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6.3-119.3 12.3-174.5 35.9a445.35 445.35 0 0 0-142 96.5c-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9A449.4 449.4 0 0 0 112 714v152a46 46 0 0 0 46 46h152.1A449.4 449.4 0 0 0 510 960h2.1c59.9 0 118-11.6 172.7-34.3a444.48 444.48 0 0 0 142.8-95.2c41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5.3-60.9-11.5-120-34.8-175.6zm-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-.6 99.6-39.7 192.9-110.1 262.7z"></path></svg></i>
                <p>&nbsp;&nbsp;카카오톡 채팅하기</p>
              </KakaoBtn>
            </a>
            <div>&nbsp;&nbsp; | &nbsp;24시간 채팅 가능</div>
          </KakaoItem>
        </KakaoContainer>
      </AskChatInfo>
      <LocationInfo>
        <AddressContainer>
          <Address>
            <span>찾아오시는 길</span>
          </Address>
          <Address>본점: 서울특별시 강남구 논현로76길 12, 2층 (역삼역 1번출구 도보7분이내)</Address>
          <Address>
            <a href="https://map.naver.com/v5/entry/place/37904725?c=14142319.6287322,4508465.5594612,15,0,0,0,dh" target="blank" rel="noreferrer noopener">
              <NaverBtn>
                <i className="anticon anticon-compass"><svg viewBox="64 64 896 896" data-icon="compass" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372zm198.4-588.1a32 32 0 0 0-24.5.5L414.9 415 296.4 686c-3.6 8.2-3.6 17.5 0 25.7 3.4 7.8 9.7 13.9 17.7 17 3.8 1.5 7.7 2.2 11.7 2.2 4.4 0 8.7-.9 12.8-2.7l271-118.6 118.5-271a32.06 32.06 0 0 0-17.7-42.7zM576.8 534.4l26.2 26.2-42.4 42.4-26.2-26.2L380 644.4 447.5 490 422 464.4l42.4-42.4 25.5 25.5L644.4 380l-67.6 154.4zM464.4 422L422 464.4l25.5 25.6 86.9 86.8 26.2 26.2 42.4-42.4-26.2-26.2-86.8-86.9z"></path></svg></i>
                <p>&nbsp;&nbsp;네이버 지도에서 보기</p>
              </NaverBtn>
            </a>                
          </Address>
          <Address>2호점: 서울특별시 강남구 논현로79길 8, 7층 (역삼역 1번출구 도보6분이내)</Address>
          <Address>
            <a href="https://map.naver.com/v5/entry/place/1993181844?c=14141361.8298292,4508849.8689431,15,0,0,0,dh" target="blank" rel="noreferrer noopener">
              <NaverBtn>
                <i className="anticon anticon-compass"><svg viewBox="64 64 896 896" data-icon="compass" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372zm198.4-588.1a32 32 0 0 0-24.5.5L414.9 415 296.4 686c-3.6 8.2-3.6 17.5 0 25.7 3.4 7.8 9.7 13.9 17.7 17 3.8 1.5 7.7 2.2 11.7 2.2 4.4 0 8.7-.9 12.8-2.7l271-118.6 118.5-271a32.06 32.06 0 0 0-17.7-42.7zM576.8 534.4l26.2 26.2-42.4 42.4-26.2-26.2L380 644.4 447.5 490 422 464.4l42.4-42.4 25.5 25.5L644.4 380l-67.6 154.4zM464.4 422L422 464.4l25.5 25.6 86.9 86.8 26.2 26.2 42.4-42.4-26.2-26.2-86.8-86.9z"></path></svg></i>
                <p>&nbsp;&nbsp;네이버 지도에서 보기</p>
              </NaverBtn>
            </a>
          </Address>
        </AddressContainer>
      </LocationInfo>
    </Wrapper>
  );
}

export default InfoContainer;
import PartnersList from "../components/Partners/PartnersList";
import SubBanner from "../components/SubBanner";
import { ContentWrapper, Title, Line } from './PageAboutUs';

function PagePartners() {
  return (
    <>
      <SubBanner />
      <ContentWrapper>
        <Title>파트너</Title>
        <Line />
        <PartnersList />
      </ContentWrapper>
    </>
  );
}

export default PagePartners;
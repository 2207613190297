import styled from "styled-components";
import { useState } from 'react';

import Office from './Office';
import Studio from './Studio';
import Semina from './Semina';

const Spaces = styled.ul`
  max-width: 700px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const Space = styled.li`
  width: 30%;
  padding: 10px 0;
  text-align: center;
  border: 1px solid #12b886;
  color: #12b886;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    border: 1px solid #12b886;
    background-color: rgba(18, 184, 134, 0.1);
  }
`; 

function SpaceCommon() {
  const [isOfficeOpened, SetIsOfficeOpened] = useState(true);
  const [isStudioOpened, setIsStudioOpened] = useState(false);
  const [isSeminaOpened, setIsSeminaOpened] = useState(false);
  const onOfficeClick = () => {
    SetIsOfficeOpened((current) => !current);
    setIsStudioOpened(false);
    setIsSeminaOpened(false);    
  }
  const onStudioClick = () => {
    SetIsOfficeOpened(false);
    setIsStudioOpened((current) => !current);
    setIsSeminaOpened(false);
  }
  const onSeminaClick = () => {
    SetIsOfficeOpened(false);
    setIsStudioOpened(false);
    setIsSeminaOpened((current) => !current);
  }  
    
  return(
    <>  
      <Spaces>
        <Space onClick={onOfficeClick}>공유오피스</Space>
        <Space onClick={onStudioClick}>스튜디오</Space>
        <Space onClick={onSeminaClick}>강의세미나실</Space>
      </Spaces>
      {isOfficeOpened && <Office />}
      {isStudioOpened && <Studio />}   
      {isSeminaOpened && <Semina />}        
    </>
  );
}

export default SpaceCommon;
import styled from 'styled-components';
import SubBanner from "../components/SubBanner";
import { ContentWrapper, Title, Line } from "./PageAboutUs";
import AboutService from "../components/About/AboutService";
import CategoryContainer from "../components/About/CategoryContainer";
import AboutOverview from "../components/About/AboutOverview";
import RecruitIntro from '../components/Recruit/RecruitIntro';
import RecruitPosition from '../components/Recruit/RecruitPosition';
import RecruitHowTo from '../components/Recruit/RecruitHowTo';
import RecruitAsk from '../components/Recruit/RecruitAsk';

const SubTitle = styled.h4`
  font-size: 1.1rem;
  color: #343a40;
  font-weight: 700;
`;

function PageRecruit() {
  return (
    <>
      <SubBanner />
      <ContentWrapper>
        <Title>크리액션 채용 공고</Title>
        <Line />
        <RecruitIntro />
        <SubTitle>회사 소개</SubTitle>
        <AboutOverview />
        <CategoryContainer />
        <AboutService />
        <SubTitle>채용 중인 포지션</SubTitle>
        <RecruitPosition />
        <SubTitle>지원 방법</SubTitle>
        <RecruitHowTo />
        <SubTitle>자주하는 질문</SubTitle>
        <RecruitAsk />
      </ContentWrapper>      
    </>
  );
}

export default PageRecruit;